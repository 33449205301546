<template>
  <main class="main">
    <section class="section dashboard">
      <div class="container">
        <div class="shortcuts">
          <div class="shortcuts__item">
            <router-link
              class="shortcuts__anchor"
              to="/armazenar"
              title="Armazenar"
            >
              <img
                class="shortcuts__item--icon"
                src="/svg/icon-store.svg"
                alt="Armazenar"
              />
              <h3 class="shortcuts__item--title">Solicitar Coleta</h3>
            </router-link>
          </div>
          <div class="shortcuts__item">
            <router-link
              class="shortcuts__anchor"
              to="/coletas-solicitadas"
              title="Solicitar Coleta"
            >
              <img
                class="shortcuts__item--icon"
                src="/svg/icon-request-collection.svg"
                alt="Solicitar Coletas"
              />
              <h3 class="shortcuts__item--title">Minhas Coletas</h3>
            </router-link>
          </div>
          <div class="shortcuts__item">
            <router-link
              class="shortcuts__anchor"
              to="/materiais-coletados"
              title="Materiais Coletados"
            >
              <img
                class="shortcuts__item--icon"
                src="/svg/icon-collected-materials.svg"
                alt="Materiais Coletados"
              />
              <h3 class="shortcuts__item--title">Materiais Coletados</h3>
            </router-link>
          </div>
          <!-- <div class="shortcuts__item">
            <router-link
              class="shortcuts__anchor"
              to="/armario"
              title="Armário"
            >
              <img
                class="shortcuts__item--icon"
                src="/svg/icon-wardrobe.svg"
                alt="Armário"
              />
              <h3 class="shortcuts__item--title">Armário</h3>
            </router-link>
          </div>
          <div class="shortcuts__item">
            <router-link
              class="shortcuts__anchor"
              to="/coletas-agendadas"
              title="Coletas Agendadas"
            >
              <img
                class="shortcuts__item--icon"
                src="/svg/icon-scheduled-collections.svg"
                alt="Coletas Agendadas"
              />
              <h3 class="shortcuts__item--title">Coletas Agendadas</h3>
            </router-link>
          </div>
          <div class="shortcuts__item">
            <router-link
              class="shortcuts__anchor"
              to="/entidades-filantropicas"
              title="Coletas Agendadas"
            >
              <img
                class="shortcuts__item--icon"
                src="/svg/icon-scheduled-collections.svg"
                alt="Entidades"
              />
              <h3 class="shortcuts__item--title">Entidades</h3>
            </router-link>
          </div>
           <div class="shortcuts__item">
            <router-link
              class="shortcuts__anchor"
              to="/extratos"
              title="Extratos"
            >
              <img
                class="shortcuts__item--icon"
                src="/svg/icon-extracts.svg"
                alt="Extratos"
              />
              <h3 class="shortcuts__item--title">Extratos</h3>
            </router-link>
          </div>
        -->
        </div>
        <div class="adverts">
          <div class="adverts__item">
            <img class="adverts__item--image" src="" alt="" />
          </div>

          <div class="adverts__item">
            <img class="adverts__item--image" src="" alt="" />
          </div>

          <div class="adverts__item">
            <img class="adverts__item--image" src="" alt="" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style></style>
